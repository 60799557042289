<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import avatar_icon from '@/assets/images/users/avatar-1.jpg'


import { getBrokerageApi } from "@/api/brokerage"


import TabAgentCommissionPreview from './components/tab_agent_commission_preview'

import TabAgentBillInfo from './components/tab_agent_bill_info.vue'
import TabAgentFeeInfo from './components/tab_agent_fee_info.vue'

import TabAgentCommissionInfo from './components/tab_agent_commission_info.vue'
import TabAgentBasicInfo from './components/tab_agent_basic_info.vue'
import TabAgentInfo from './components/tab_agent_info.vue'




/**
 * Agent View component
 */
export default {
  page: {
    title: "Agent",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {

    agent_id: {
      type: String
    }
  },

  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Agent",
          href: "/brokerage/agent",
        },
        {
          text: "View",
          active: true,
        },
      ],
      avatar: avatar_icon,
      edit: false,
      agent: {
        fees: {},
        fields: {
          tax_info : {},
        },
        bill: {items : []}
      },

    };
  },


  components: {
    Layout,
    PageHeader,

    TabAgentCommissionPreview,

    TabAgentBillInfo,
    TabAgentFeeInfo,
    TabAgentCommissionInfo,
    // TabAgentMiscellaneousInfo,
    // TabAgentSocialMedialInfo,
    // TabAgentFinancialInfo,
    // TabAgentKeyDateInfo,
    // TabAgentOfficeInfo,
    // TabAgentBusinessInfo,
    TabAgentBasicInfo,
    TabAgentInfo

  },


  methods: {

    onTabChange(old_tab, new_tab) {
      (old_tab)
      this.$refs.infoTabs.tabs[new_tab].data = this.agent
    }


  },


  created() {
   

  },

  mounted() {

    this.$nextTick(() => {

      let query_agent_id = this.agent_id
      if (!query_agent_id) {
        query_agent_id = this.$route.query.agent_id
      }
      
      if (query_agent_id) {
        getBrokerageApi().agentApi.agent_detail({ agent_id: query_agent_id }).then((res) => {
          if (res.errCode == 0) {
            this.agent = Object.assign({}, res.data)
            if (!this.agent.fees) {
              this.agent.fees = {}
            }
            if (!this.agent.fields) {
              this.agent.fields = {}
            }

            if (!this.agent.fields.tax_info) {
              this.agent.fields.tax_info = {}
            }
          }
        })
      }
    })

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row"> 
        <TabAgentInfo :data="agent" ref="agentInfoRef" />
      <!-- end col -->
    </div>

    <div class="row pt-5">
      <div class="col-xxl-12 col-lg-12">

        <div class="row">
          <b-tabs class="mt-n5 pt-2" content-class="card card-body mt-0" nav-class="nav-tabs-custom"
            nav-wrapper-class="nav-tabs-custom" @activate-tab="onTabChange" ref="infoTabs">

            <b-tab title="Basic" active>
              <TabAgentBasicInfo :data="agent"  />
            </b-tab>

            <b-tab title="Fee">
              <TabAgentFeeInfo :data="agent" />
            </b-tab>

            <b-tab title="Files">
              <TabAgentBasicInfo  v-if="0" :data="agent" />
            </b-tab>

            <b-tab title="Monthly Bill">
              <TabAgentBillInfo :data="agent" />
            </b-tab>
            <b-tab title="Commission">
              <TabAgentCommissionInfo :data="agent" />
            </b-tab>
            
            <b-tab title="Deal Preview">
              <TabAgentCommissionPreview :data="agent" />
            </b-tab>

            

          </b-tabs>
        </div>

      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
