





<script>


/**
 * Agent Basic Info component
 */

import { financial } from '@/api/misc'
import { getBrokerageApi } from "@/api/brokerage"
export default {



  props: ['data'],
  components: {

  },


  data() {

    return {
      fields: [
        {
          key: "FeeName",
        },
        {
          key: "Fee",
          sortable: true,
        },
        {
          key: "FeeTax",
          sortable: true,
        },
      ],

      query_month : '',
      total_amount : 0,
      commission : [],
      summary_year : '',
      summary      : {
        deal_amount     : 0,
        deal_count      : 0,
        pm_amount       : 0,
        refer_amount    : 0,
      },
    }

  },
  methods: {
    f(n, d = 2) {
      return financial(n, d)
    },

    query_agent_commission() {
      getBrokerageApi().agentApi.agent_commission({ agent_id: this.data.id }).then((res) => {
          if (res.errCode == 0) {
            this.commission = res.data.commissions
            this.summary    = res.data.summary
            this.total_amount = this.commission.reduce((a, c) => a + Number(c.incoming), 0)
          }
        })
    },

  },

  created() {

  },

  mounted() {
    

  },


  watch: {
    data() {
      this.query_agent_commission()
    }
  }

};
</script>


<template>
  

      <div class="row">
        <div class="col-lg-12">
        
          <div class="card">
            <div class="card-body">

              <div class="invoice-title">
                <div class="text-muted">
                  <h4 class="float-end font-size-15">

                    <span class="badge bg-success font-size-12 ms-2" ></span>
                    <span class="badge bg-success font-size-12 ms-2"></span>
                  </h4>
                  <p class="mb-1">Agent       :{{ data.full_name }}</p>
                  <p class="mb-1">Year        :{{ summary_year}}</p>
                  <p class="mb-1">Deal Count  :{{ summary.deal_count }}</p>
                  <p class="mb-1">Deal amount :{{ summary.deal_amount }}</p>
                  <p class="mb-1">PM amount   :{{ summary.pm_amount }}</p>
                  <p class="mb-1">Refer amount:{{ summary.refer_amount }}</p>

                </div>
              </div>

              <hr class="my-4" />
              <div class="py-2">
                <h5 class="font-size-15">Commission Summary</h5>

                <div class="table-responsive">
                  <table class="table align-middle table-nowrap table-centered mb-0">
                    <thead>
                      <tr>
                        <th style="width: 100px;">No.</th>
                        <th>Type</th>
                        <th>Transaction</th>
                        <th class="text-end" style="width: 120px;">Amount</th>
                      </tr>
                    </thead>
                    <!-- end thead -->
                    <tbody>
                      <tr v-for="(item, idx) in commission" :key="'comm_item_'+idx">
                        <th scope="row">{{ idx + 1 }}</th>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">{{ item.ref_type }} </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <td>{{item.deal? item.deal.order_deal_no: ''}}</td>
                        <td class="text-end">${{ f(item.incoming).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->
                    
                      <tr>
                        <th scope="row" colspan="3" class="border-0 text-end">
                          Total
                        </th>
                        <td class="border-0 text-end">
                          <h4 class="m-0 fw-semibold">${{ f(total_amount).toLocaleString() }}</h4>
                        </td>
                      </tr>
                      <!-- end tr -->
                    </tbody>
                    <!-- end tbody -->
                  </table>
                  <!-- end table -->
                </div>
                <!-- end table responsive -->
                <div class="d-print-none mt-4">
                  <div class="float-end">
                    <a href="javascript:void(0);" class="btn btn-primary w-md">Export Pdf</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->

</template>